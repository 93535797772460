import { getOrganizationAdminPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { useModal } from '../../context/ModalContext'
import ViewOrganizationProfile from '../../modals/ViewOrganizationProfile'
import Avatar from '../Avatar'
import './index.css'

function SpaceUserItem ({ person }) {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const isWorkspaceAdmin = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )

  const displayPerson = () => {
    makeModal({
      modal: <ViewOrganizationProfile person={person} />,
      title: `${person?.contact?.firstName}'s Profile`,
      modalBackgroundClose: true,
      slideOut: true
    })
  }

  return (
    <div
      className={`space-user-item ${isWorkspaceAdmin && 'clickable'}`}
      data-testid={`${person?.contact?.firstName}-${person?.contact?.lastName}-avatar`}
      {...(isWorkspaceAdmin && { onClick: () => displayPerson() })}
    >
      <div className="space-user-name-container">
        <Avatar className="space-user-item-avatar" firstName={person?.contact?.firstName} lastName={person?.contact?.lastName} avatar={person?.user?.avatar} />
        <div className="name-container">{person?.contact?.firstName} {person?.contact?.lastName}</div>
      </div>
    </div>
  )
}

export default SpaceUserItem
