import { useCallback, useEffect, useState } from 'react'
import { useAuthContext } from '../../context/AuthContext'
import SelectPermission from '../../components/SelectPermission'
import SelectSpaces from '../../components/SelectSpaces'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import {
  useAddPersonMutation,
  useEditPersonMutation,
  useResendInvitationMutation
} from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import { formatPhoneNumberInput } from '../../shared/utilities'
import { PuffLoader } from 'react-spinners'
import { logError } from '../../shared/logger'
import {
  validateEmail,
  validateRoleRequirements,
  validateFirstName,
  validateLastName
} from '../../shared/validators'
import GoogleAddressSearch from '../../components/GoogleAddressSearch'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { Entity, roles } from '../../shared/enums'
import { OrganizationRole, InvitationStatus } from '../../types'
import { parsedErrorMessage } from '../../shared/errors'
import { formattedDate, parseDate } from '../../shared/dates'
import { useNavigate } from 'react-router-dom'

interface AddOrEditPersonProps {
  existingPerson?: OrganizationRole;
  spaceId?: number;
}

function AddOrEditPerson ({ existingPerson }: AddOrEditPersonProps) {
  const navigate = useNavigate()
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()
  const focusRef = useCallback((node) => {
    if (node !== null) {
      node.focus()
    }
  }, [])

  const {
    user: prevUser,
    contact: prevContact,
    title: prevTitle,
    organizationId: prevOrganizationId,
    spaceRoles: prevSpaceRoles
  } = existingPerson || {}

  const initialState = {
    contact: {
      firstName: prevContact?.firstName || '',
      lastName: prevContact?.lastName || '',
      notes: prevContact?.notes || '',
      dob: prevContact?.dob ? new Date(prevContact?.dob) : null,
      address: prevContact?.address || '',
      phone: prevContact?.phone || '',
      id: prevContact?.id || '',
      email: prevContact?.email || prevUser?.email || ''
    },
    title: prevTitle || roles.member,
    organizationId: prevOrganizationId || currentUser?.currentOrganizationId,
    spaceRoles: prevSpaceRoles?.length ? prevSpaceRoles : []
  } as OrganizationRole

  const [person, setPerson] = useState<OrganizationRole>({
    ...existingPerson,
    ...initialState
  })

  const [submitted, setSubmitted] = useState<boolean>(false)

  const { email } = person?.contact || {}
  const { firstName, lastName, phone, dob, address, notes } =
    person?.contact || {}
  const { title } = person || {}
  const [dateInput, setDateInput] = useState<string>(formattedDate(dob))

  const isAddingUser =
    (!prevContact?.email || email !== prevContact?.email) && !prevUser && email !== ''

  useEffect(() => {
    if (submitted) {
      isFormValid()
    }
  }, [firstName, lastName, email, submitted, title, dob])

  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  function updatePerson (obj) {
    setPerson((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  function updateSpaceRoles (arr) {
    setPerson((prevState) => ({
      ...prevState,
      spaceRoles: arr
    }))
  }

  function updateContact (obj) {
    setPerson((prevState) => ({
      ...prevState,
      contact: {
        ...prevState?.contact,
        ...obj
      }
    }))
  }

  const setDob = (date: string) => {
    const parsedDate = parseDate(date)
    const parsedDateOffsetForDST = parsedDate?.setHours(
      parsedDate?.getHours() + 12
    )
    updateContact({ dob: parsedDateOffsetForDST })
  }

  const addPerson = useAddPersonMutation()
  const editPerson = useEditPersonMutation()
  const resendInvite = useResendInvitationMutation()

  const onResendInvite = () => {
    resendInvite.mutate(person?.invitationCode ?? '')
  }

  async function callMutation () {
    try {
      if (existingPerson) {
        await editPerson.mutateAsync(person)
        clearModal()
      } else {
        const response = await addPerson.mutateAsync(person)
        navigate(`/communities/settings/people/${response.id}`)
      }
    } catch (error) {
      logError(error)
      setError(parsedErrorMessage(error))
    }
  }

  function isFormValid () {
    try {
      validateFirstName(firstName)
      validateLastName(lastName)
      validateRoleRequirements({
        title,
        email
      })
      email !== '' && validateEmail(email)
      setError(null)
      return true
    } catch (error) {
      setError(parsedErrorMessage(error))
      return false
    }
  }

  async function handleSubmit () {
    setSubmitted(true)
    isFormValid() && (await callMutation())
  }

  function handleEmailChange (e) {
    const input = e.target.value
    updateContact({ email: input })
  }

  const additionalDetails = (
    <div>
      <input
        type="tel"
        name="phone"
        data-testid="phone-input"
        className="phone-field"
        value={phone && formatPhoneNumberInput(phone?.slice(2))}
        onChange={(e) =>
          updateContact({
            [e.target.name]:
              e.target.value && `+1${formatPhoneNumberInput(e.target.value)}`
          })
        }
        placeholder="Phone"
      />
      <input
        type="date"
        name="dob"
        data-testid="dob-input"
        className="date-field"
        value={dateInput}
        onBlur={() => setDob(dateInput)}
        onChange={(e) => setDateInput(e.target.value)}
        placeholder="Birthday"
      />
      <GoogleAddressSearch
        address={address}
        onChange={(e) => updateContact({ [e.target.name]: e.target.value })}
        setAddress={(address) => updateContact({ address })}
        placeholder="Address"
      />
      {!existingPerson && (
        <textarea
          name="notes"
          data-testid="notes-textarea"
          value={notes ?? ''}
          onChange={(e) => updateContact({ [e.target.name]: e.target.value })}
          placeholder="Notes"
        />
      )}
    </div>
  )

  function getSubmitButtonText () {
    if (existingPerson) {
      return isAddingUser ? 'Save & Invite' : 'Save'
    } else {
      return isAddingUser ? 'Invite Person' : 'Add Person'
    }
  }
  const isAdminOrAbove = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )

  function showEmailOrSearchBar () {
    return (
      <input
        data-testid="email-input"
        type="text"
        value={person.contact?.email || ''}
        className="email-input"
        onChange={handleEmailChange}
        placeholder="Email"
        disabled={!!existingPerson && !!prevUser?.email}
      />
    )
  }

  return (
    <>
      {!existingPerson && (
        <div className="permission-subtitle">
          Who would you like to add to your {Entity.Community}?
        </div>
      )}
      {error && error.length > 0 && (
        <div
          className="message-banner"
          tabIndex={0}
          ref={focusRef}
          data-testid="invite-inline-error-message"
        >
          {error}
        </div>
      )}
      {showEmailOrSearchBar()}
      {person.invitationStatus === InvitationStatus.Pending && (
        <div
          style={{ fontSize: '0.9rem', opacity: 0.75, marginBottom: '1rem' }}
        >
          {isAddingUser
            ? 'A new invite will be sent to this email address.'
            : (
            <>
              Invitation pending.{' '}
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onResendInvite}>
                Resend invite?
              </span>
            </>)}
        </div>
      )}
      <div className={'name-inputs'}>
        <input
          data-testid="first-name-input"
          type="text"
          name="firstName"
          className="first-name-input"
          value={firstName}
          onChange={(e) => {
            updateContact({ [e.target.name]: e.target.value })
          }}
          placeholder="First Name"
        />
        <input
          data-testid="last-name-input"
          type="text"
          name="lastName"
          className="last-name-input"
          value={lastName}
          onChange={(e) => {
            updateContact({ [e.target.name]: e.target.value })
          }}
          placeholder="Last Name"
        />
      </div>
      {!existingPerson && (
        <button
          data-testid="additional-details"
          className={`button transparent additional-details-button ${
            showDetails ? 'open' : 'closed'
          }`}
          onClick={() => setShowDetails((prev) => !prev)}
        >
          {showDetails
            ? (
            <ExpandLess fontSize={'small'} />
              )
            : (
            <ExpandMore fontSize={'small'} />
              )}
          Additional Details
        </button>
      )}
      {(showDetails || !!existingPerson) && additionalDetails}
      {isAdminOrAbove && (
        <>
          <div className="permission-subtitle">Role in {Entity.Community}</div>
          <SelectPermission
            person={person}
            updatePerson={updatePerson}
            prevTitle={prevTitle}
          />
        </>
      )}
       {isAdminOrAbove && !existingPerson && (
          <SelectSpaces
            spaceRoles={person.spaceRoles || []}
            updateSpaceRoles={updateSpaceRoles}
            role={person.title}
          />
       )}
      <div className="modal-footer right-align-buttons">
        <div className="buttons-container">
          <button
            className="cancel-button button secondary"
            onClick={clearModal}
          >
            Cancel
          </button>
          <button
            className="button permission-button"
            data-testid="permission-button"
            onClick={handleSubmit}
          >
            {editPerson.isLoading || addPerson.isLoading
              ? (
              <PuffLoader color="#fff" size={21} />
                )
              : (
                  getSubmitButtonText()
                )}
          </button>
        </div>
      </div>
    </>
  )
}

export default AddOrEditPerson
