import { getSpaceLeaderPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import SpaceMembersDisplay from '../../components/SpaceMembersDisplay'
import ManageSpaceRoles from '../../components/ManageSpaceRoles'
import ManageSpaceSettings from '../../components/ManageSpaceSettings'
import { useState } from 'react'
import { Space } from '../../types'
import './index.css'

interface PersonSubNavProps {
  space: Space;
}

function ManageSpace ({ space }: PersonSubNavProps) {
  const { currentUser } = useAuthContext()
  const isHostAndAbove = isAuthorized(
    getSpaceLeaderPolicy(space.id, currentUser?.currentOrganizationId),
    currentUser
  )

  const [selectedNav, setSelectedNav] = useState<string>('members')

  const subNavHeaderContent = [
    { title: `Members (${space?.spaceRoles?.length})`, action: () => setSelectedNav('members'), key: 'members' },
    { title: 'Invite', action: () => setSelectedNav('invite'), key: 'invite' },
    { title: 'Settings', action: () => setSelectedNav('settings'), key: 'settings' }
  ]

  const subNavKey = {
    members: 'members',
    invite: 'invite',
    settings: 'settings'
  }

  const subNavHeaders = subNavHeaderContent.map((header) => {
    if (
      !isHostAndAbove &&
      (header.key === subNavKey.invite || header.key === subNavKey.settings)
    ) {
      return null
    }

    return (
      <div
        key={header.key}
        className={
          selectedNav === header.key
            ? 'organization-profile-subnav-header-selected'
            : 'organization-profile-subnav-header-item'
        }
        onClick={header.action}
      >
        {header.title}
      </div>
    )
  })

  return (
    <div className="organization-profile-subnav-wrapper">
      <div className="space-subnav-headers">{subNavHeaders}</div>
      {selectedNav === subNavKey.members && (
        <SpaceMembersDisplay space={space} />
      )}
      {isHostAndAbove && selectedNav === subNavKey.invite && (
        <ManageSpaceRoles existingSpace={space} showAllMembers />
      )}
      {isHostAndAbove && selectedNav === subNavKey.settings && (
        <ManageSpaceSettings existingSpace={space} openedFromAdmin />
      )}
    </div>
  )
}

export default ManageSpace
