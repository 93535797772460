import { useExerciseListByUserQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { OrganizationRole, Course } from '../../types'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { useModal } from '../../context/ModalContext'
import ManageUserCourses from '../../modals/ManageUserCourses'
import CoursePrestige from '../../modals/CoursePrestige'

interface ExerciseProgressDisplayProps {
  userId?: number;
  userCourses?: Course[];
  id: number;
  spaceId?: number;
  person?: OrganizationRole;
}

function ExerciseProgressDisplay ({
  userId,
  userCourses,
  id,
  spaceId,
  person
}: ExerciseProgressDisplayProps) {
  const navigate = useNavigate()

  const { makeModal } = useModal()

  const { currentUser } = useAuthContext()
  const isAdminOrAbove = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )

  const {
    isLoading,
    isError,
    data: exercises,
    error
  } = useExerciseListByUserQuery(id, userId ?? 0)

  if (isLoading) {
    return null
  }

  if (isError) {
    logError(error)
  }

  function hasSpaceIdNavigation (courseVersionId) {
    if (spaceId) {
      navigate(
        `/communities/settings/people/${id}/course/${courseVersionId}/?spaceId=${spaceId}`
      )
    } else {
      navigate(
        `/communities/settings/people/${id}/course/${courseVersionId}`
      )
    }
  }

  const handlePrestigeModal = (courseVersionId) => {
    makeModal({
      title: 'Prestige Course',
      modal: (
        <CoursePrestige
          id={id}
          courseVersionId={courseVersionId}
        />
      )
    })
  }

  const exerciseProgressPercentageDisplay = (courseVersionId) => {
    const filteredCourse = exercises?.filter(
      (exercise) => exercise?.section?.courseVersionId === courseVersionId
    )
    const totalExercises = filteredCourse ? filteredCourse?.length : 0
    const completeExercises = filteredCourse?.filter(
      (exercise) => exercise?.isComplete === true
    ).length ?? 0

    const percentageOfExercisesComplete =
      totalExercises !== 0
        ? `${Math.round(
            (completeExercises / totalExercises) * 100
          )}%`
        : '0%'

    return (
          <div className="progress-display-wrapper">
            <div className="progress-bar-display">
              <div
                className="progress-bar-display-inner"
                style={{ width: percentageOfExercisesComplete }}
              ></div>
            </div>
            {totalExercises !== 0
              ? (
                  percentageOfExercisesComplete === '100%'
                    ? (
                      <button className="reset-program-button button" onClick={(e) => {
                        e.stopPropagation()
                        handlePrestigeModal(courseVersionId)
                      }}>
                        Reset Completed Course
                      </button>
                      )
                    : (
                      <div className="progress-bar-text">
                        {percentageOfExercisesComplete} completed so far
                      </div>
                      )
                )
              : (
                <div className="progress-bar-text">
                  No exercises tied to this course
                </div>
                )}
          </div>
    )
  }

  const courseExerciseDisplay = userCourses?.map((course) => {
    return course?.courseVersion
      ?.filter((version) => version?.space && version.space.length !== 0)
      ?.map((courseVersion) => {
        return (
          <div
            className="program-progress-card-container"
            key={courseVersion?.id}
            onClick={
              userId
                ? () => navigate(`/courses/${courseVersion?.id}`)
                : () => hasSpaceIdNavigation(courseVersion?.id)
            }
          >
            <div className="program-card-name">
              {course?.name} v{courseVersion?.version}.0.0
            </div>
            <div>{exerciseProgressPercentageDisplay(courseVersion?.id)}</div>
          </div>
        )
      })
  })

  return (
    <>
      <div>{courseExerciseDisplay}</div>
      <div className="person-spaces-footer">
        {isAdminOrAbove && person && (
          <div
            className="button"
            data-testid="organization-profile-edit-spaceroles-button"
            onClick={() =>
              makeModal({
                modal: <ManageUserCourses person={person} />
              })
            }
          >
            Edit courses
          </div>
        )}
      </div>
    </>
  )
}

export default ExerciseProgressDisplay
