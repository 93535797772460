import { formatConnectionDate, sortProgressEntryByExerciseId } from '../../shared/utilities'
import { useParams, useNavigate } from 'react-router-dom'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import { useConnectionQuery } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { logError } from '../../shared/logger'
import ListExercisesItem from '../../components/ListExercisesItem'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import AchievementItem from '../../components/AchievementItem'
import LoadingScreen from '../../components/LoadingScreen'
import './index.css'
import ErrorPage from '../ErrorPage'
import { Achievement } from '../../types'
import { Entity } from '../../shared/enums'

function SpaceConnectionHistory () {
  const navigate = useNavigate()
  const { uid } = useParams()
  const { currentUser } = useAuthContext()
  const { isLoading, isError, error, data: connection } = useConnectionQuery(uid ?? '', { enabled: !!currentUser })

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar backTo="/" title="Connection" />
        <LoadingScreen />
      </>)
  }

  if (isError) {
    logError(error)
    return (
      <>
        <SecondaryNavBar backTo="/" title="Connection" />
      </>)
  }

  if (!connection?.uid) {
    return (
      <>
        <SecondaryNavBar backTo="/" title="Connection" />
        <div className="connection-report-container">
          <ErrorPage message="404, this page does not exist" action={() => navigate('/')} actionTitle="Return home?" />
        </div>
      </>
    )
  }

  const getSubnavActions = () => {
    if (isAuthorized(getSpaceLeaderPolicy(connection.spaceId, currentUser?.currentOrganizationId), currentUser)) {
      return [{
        action: () => navigate(`/connections/${uid}`),
        actionTitle: connection.endTime ? `Edit ${Entity.Event}` : `Join ${Entity.Event}`
      }]
    }
    return []
  }

  const { connectionAttendance } = { ...connection }

  const spaceProgressItems = (
    connectionAttendance?.map(attendanceEntry => {
      const memberAchievements: Achievement[] = attendanceEntry.exerciseProgress?.filter(progressEntry =>
        progressEntry.exercise?.achievements && progressEntry.exercise.achievements?.length > 0)
        .map((progressEntry) => (progressEntry?.exercise?.achievements?.[0] ?? {} as Achievement)) ?? []

      return (
        <div className="connection-report-user-container" key={attendanceEntry.organizationRoleId}>
          <h3>{attendanceEntry.contact?.firstName} {attendanceEntry.contact?.lastName}</h3>
          {attendanceEntry.exerciseProgress?.length === 0 && <p>Nothing completed this connection</p>}
          {memberAchievements && memberAchievements.length > 0
            ? <AchievementItem
              memberAchievements={memberAchievements}
            />
            : <></>
          }
          {attendanceEntry.exerciseProgress?.sort(sortProgressEntryByExerciseId)
            .map(progressEntry => (
              <ListExercisesItem
                key={progressEntry?.exercise?.id}
                exercise={progressEntry.exercise}
                readOnlyCompleted={true}
                currentUser={currentUser}
              />
            ))}
        </div>)
    }
    ))

  return (
    <>
      <SecondaryNavBar
        backTo={`/spaces/${connection.spaceId}`}
        title="Connection"
        subtitle={formatConnectionDate(connection.startTime)}
        onClickActions={getSubnavActions()} />
      <div className="connection-report-container">
        {(connectionAttendance && connectionAttendance.length >= 1) ? spaceProgressItems : <p>There is no data for this connection</p>}
      </div>
    </>
  )
}

export default SpaceConnectionHistory
