import { useOrganizationTagsQuery, useUpdateContactTagsMutation } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import LoadingScreen from '../../components/LoadingScreen'
import { logError } from '../../shared/logger'
import { useModal } from '../../context/ModalContext'
import { useState } from 'react'
import { PuffLoader } from 'react-spinners'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import './index.css'

function AddOrEditPersonTags ({ contact }) {
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()
  const editTags = useUpdateContactTagsMutation()
  const [personTags, setPersonTags] = useState(contact?.tags?.map(contactTag => contactTag?.tag?.name ?? ''))
  const [errorMessage, setErrorMessage] = useState('')

  const { isLoading, isError, data: tags, error } = useOrganizationTagsQuery({ organizationId: currentUser?.currentOrganizationId, options: { enabled: !!currentUser } })

  async function handleSave () {
    try {
      await editTags.mutateAsync({ contactId: contact.id, names: personTags })
      setErrorMessage('')
    } catch (error) {
      logError(error)
    }
  }

  function handleChange (value) {
    setErrorMessage('')
    setPersonTags(value)
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
  }

  return (
    <div>
      {errorMessage && <div className="edit-error">{errorMessage}</div>}
      <div style={{ marginBottom: '1rem' }}>Tags can be used to categorize and identify the type of person, such as roles, skills, or attributes, allowing for better organization and personalization.</div>
      <div className="tag-container">
        <Autocomplete
          multiple
          id="tags-standard"
          options={Array.isArray(tags) ? tags.map(tag => tag?.name ?? '') : []}
          freeSolo
          value={personTags}
          onChange={(event, newValue) => {
            handleChange(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Tags..."
            />
          )}
        />
      </div>
      <div className="modal-footer">
          <div className="button secondary" onClick={clearModal}>Cancel</div>
          <div className="button" onClick={() => handleSave()}>
            {editTags.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Save'}
          </div>
        </div>

    </div>
  )
}

export default AddOrEditPersonTags
