import { LAST_ORGANIZATION_ID_KEY } from '../context/AuthContext'
import {
  ActionExercises,
  Checkin,
  Connection,
  Exercise,
  Goal,
  OrganizationCourse,
  OrganizationRole,
  Plan,
  Course,
  PromoCode,
  Space,
  User,
  Organization
} from '../types'
import { config } from './config'

const makeHeaders = () => {
  const organizationId = localStorage.getItem(LAST_ORGANIZATION_ID_KEY) ?? '0'

  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-Organization-Id': organizationId
  }
}

const apiGet = async <T>(path: string): Promise<T> => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'GET',
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const apiPut = async <T>(path: string, body?: any): Promise<T> => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const apiPost = async <T>(path: string, body?: any): Promise<T> => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const apiDelete = async (path: string, body?: any) => {
  await fetch(`${config.API_HOST}${path}`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
}

const getLastOrganizationId = (): number => {
  return parseInt(localStorage.getItem(LAST_ORGANIZATION_ID_KEY) ?? '0')
}

export async function getUser (): Promise<User> {
  const lastOrganizationId = getLastOrganizationId()
  const validOrganizationId =
    lastOrganizationId !== 0 ? lastOrganizationId : null

  const user = await apiGet<User>(
    `/user${
      validOrganizationId !== null
        ? `?organizationId=${validOrganizationId}`
        : ''
    }`
  )
  const activeOrganizationRoles =
    user?.organizationRoles?.filter((role) => role.status !== 'INACTIVE') ?? []

  if (
    activeOrganizationRoles.find(
      (role) => role.organization?.id === lastOrganizationId
    )
  ) {
    return {
      ...user,
      currentOrganizationId: lastOrganizationId
    }
  }

  return {
    ...user,
    currentOrganizationId: activeOrganizationRoles[0]?.organization?.id || null
  }
}

export async function putUser (user: User) {
  const newUser = await apiPut<User>('/user', user)
  return {
    ...newUser,
    currentOrganizationId:
      getLastOrganizationId() || user?.organizationRoles?.[0]?.organization?.id
  }
}

export async function getExerciseListBySpace ({ organizationRoleId, spaceId }) {
  return await apiGet<Exercise[]>(
    `/exercise_progress?organizationRoleId=${organizationRoleId}${
      spaceId ? `&spaceId=${spaceId}` : ''
    }`
  )
}

export async function getExerciseListByUser ({ organizationRoleId, userId }) {
  return await apiGet<Exercise[]>(
    `/exercise_progress?organizationRoleId=${organizationRoleId}&userId=${userId}`
  )
}

export async function getSpaces ({ filter, isArchived }) {
  const queryString =
    filter && isArchived != null
      ? `?filter=${filter}&isArchived=${isArchived}`
      : filter
        ? `?filter=${filter}`
        : isArchived != null
          ? `?isArchived=${isArchived}`
          : ''
  return await apiGet<Space[]>(`/spaces${queryString}`)
}

export async function postSpaces (space) {
  return await apiPost('/spaces', space)
}

export async function putSpaces (space) {
  const removedConnectionSpace = { ...space, connections: null }
  const putSpaceResponse = await apiPut<Space>(
    `/spaces/${space.id}`,
    removedConnectionSpace
  )
  const connections = (
    await apiGet<Connection[]>(
      `/connections${space.id ? `?spaceId=${space.id}` : ''}`
    )
  ).sort((a, b) => b.id - a.id)
  return { ...putSpaceResponse, connections }
}

export async function getSpace (id) {
  const [space, connections] = await Promise.all([
    apiGet<Space>(`/spaces/${id}`),
    apiGet<Connection[]>(`/connections${id ? `?spaceId=${id}` : ''}`)
  ])
  return { ...space, connections }
}

export async function searchPeopleByEmail ({ email, spaceId }) {
  const results = await apiGet<OrganizationRole[]>(
    `/organization_roles/?email=${email}&spaceId=${spaceId}`
  )
  return !!results
}

export async function getPeople () {
  return await apiGet<OrganizationRole[]>('/organization_roles')
}

export async function getPeopleGallery () {
  return await apiGet<OrganizationRole[]>('/people_gallery')
}

export async function getPeopleBySpace (spaceId) {
  return await apiGet<OrganizationRole[]>(
    `/organization_roles${spaceId ? `?spaceId=${spaceId}` : ''}`
  )
}

export async function updatePerson (person) {
  return await apiPut(`/organization_roles/${person?.id}`, person)
}

export async function addPerson (person) {
  return await apiPost('/organization_roles', person)
}

export async function resendInvite (inviteCode: string) {
  return await apiPost(`/organization_roles/resend_invite/${inviteCode}`)
}

export async function getOrganizationRole ({ id, spaceId }) {
  return await apiGet<OrganizationRole>(
    `/organization_roles/${id}${spaceId ? `?spaceId=${spaceId}` : ''}`
  )
}

export async function putPersonStatus ({ organizationRoleId, status }) {
  return await apiPut(`/organization_roles/${organizationRoleId}:id/status`, {
    organizationRoleId,
    status
  })
}

export async function putPeopleStatus ({ organizationRoles, status }) {
  return await apiPut('/organization_roles/status', {
    organizationRoles,
    status
  })
}

export async function startConnection ({ spaceId, location, startTime }) {
  return await apiPost('/connections', { spaceId, location, startTime })
}

export async function getConnection (connectionUid) {
  return await apiGet<Connection>(`/connections/${connectionUid}`)
}

export async function endConnection (connectionUid) {
  return await apiPut(`/connections/${connectionUid}/end`)
}

export async function updateConnection ({ connectionUid, startTime, location }) {
  return await apiPut(`/connections/${connectionUid}`, {
    startTime: new Date(startTime),
    location
  })
}

export async function addOrganizationCourse (body) {
  return await apiPost('/organization_courses', body)
}

export async function getOrganizationCourses () {
  return await apiGet<OrganizationCourse[]>('/organization_courses')
}

export async function getOrganizationUserCourses (organizationRoleId) {
  return await apiGet<Course[]>(
    `/user_courses?organizationRoleId=${organizationRoleId}`
  )
}

export async function editContactNotes ({
  contactId,
  notes,
  organizationRoleId
}) {
  await apiPut(
    `/contacts/${contactId}/notes?organizationRoleId=${organizationRoleId}`,
    { notes }
  )
}

export async function postExerciseProgress ({
  spaceId,
  exerciseId,
  connectionId,
  userId,
  organizationRoleId
}) {
  return await apiPost('/exercise_progress', {
    exerciseId,
    connectionId,
    userId,
    spaceId,
    organizationRoleId
  })
}

export async function deleteExerciseProgress ({
  exerciseProgressId: id,
  spaceId,
  userId,
  organizationRoleId
}) {
  return await apiDelete(`/exercise_progress/${id}}`, {
    spaceId,
    userId,
    organizationRoleId
  })
}

export async function getUsernameSearchResults ({ username, spaceId }) {
  return await apiGet<User[]>(
    `/users/search/?username=${username}${spaceId ? `&spaceId=${spaceId}` : ''}`
  )
}

export async function getOrganizationReports (organizationId) {
  return await apiGet(`/communities/${organizationId}/reports`)
}

export async function getOrganizationDailyReports (organizationId) {
  return await apiGet(`/communities/${organizationId}/reports_daily`)
}

export async function getWeeklyReport ({
  organizationId,
  startWeekLocal,
  endWeekLocal
}) {
  return await apiGet(
    `/communities/${organizationId}/weekly_report?weekstart=${startWeekLocal}&weekend=${endWeekLocal}`
  )
}

export async function getDailyReport ({ startDay, nextDay }) {
  return await apiGet(`/checkin/progress?start=${startDay}&next=${nextDay}`)
}

export async function getCourse (uid) {
  return await apiGet<Course>(`/courses/${uid}`)
}

export async function getPublicCourses () {
  return await apiGet<Course[]>('/course')
}

export async function getPlans () {
  return await apiGet<Plan>('/plans')
}

export async function createOrganizationCourse ({ organizationId, courseUid }) {
  return await apiPost<OrganizationCourse>(
    `/communities/${organizationId}/courses`,
    { courseUid }
  )
}

export async function createOrganization ({ name, courseUid }) {
  return await apiPost<Organization>('/communities', {
    name,
    courseUid
  })
}

export type updateOrganizationRequest = {
  organizationId: number;
  name: string;
  domain?: string;
  logoUrl?: string;
  memberSpaceCreation: boolean;
  dailyCheckin?: boolean;
  dailyCheckinDaysOfWeek?: string;
  editConnection?: boolean;
  defaultSpace: boolean;
  shareContactData: boolean;
};

export async function updateOrganization ({
  organizationId,
  name,
  domain,
  logoUrl,
  memberSpaceCreation,
  dailyCheckin,
  dailyCheckinDaysOfWeek,
  editConnection,
  defaultSpace,
  shareContactData
}: updateOrganizationRequest) {
  return await apiPut(`/communities/${organizationId}`, {
    name,
    logoUrl,
    memberSpaceCreation,
    dailyCheckin,
    dailyCheckinDaysOfWeek,
    editConnection,
    domain,
    defaultSpace,
    shareContactData
  })
}

export async function updateSpaceRoles (request) {
  return await apiPut(
    `/organization_roles/${request?.organizationRoleId}/space_roles`,
    request
  )
}

export async function generateAvatarUploadUrlForOrganization (
  organizationId: number
) {
  return await apiPost<string>('/organization/avatar', { organizationId })
}

export async function generateAvatarUploadUrlForUser () {
  return await apiPost<string>('/user/avatar')
}

export function getBillableSeats () {
  return apiGet<OrganizationRole[]>('/billable_seats')
}

export async function getOrganizationSubscription (organizationId) {
  return await apiGet(`/communities/${organizationId}/subscription`)
}

export async function putPromoCode (body) {
  return await apiPut(`/communities/${body.organizationId}/promo_code`, body)
}

export async function checkIsValidPromoCode ({ promoCode, organizationId }) {
  return await apiGet<PromoCode[]>(
    `/communities/${organizationId}/promo_code/?code=${promoCode}`
  )
}

export async function unfreezeOrganizationAndUpdatePaymentMethod ({
  organizationId,
  paymentMethodId,
  customerId
}) {
  return await apiPut(`/communities/${organizationId}/update_payment`, {
    paymentMethodId,
    customerId
  })
}

export async function getOrganizationGoals (organizationId) {
  return await apiGet<Goal[]>(`/communities/${organizationId}/goals`)
}

export async function createOrganizationGoal (title) {
  return await apiPost('/goal', { title })
}

export async function updateOrganizationGoal ({ goalId, title }) {
  return await apiPut(`/goal/${goalId}`, { title })
}

export async function deleteOrganizationGoal ({ goalId }) {
  return await apiDelete(`/goal/${goalId}`)
}

export async function getUserActions ({ organizationRoleId, spaceId }) {
  return await apiGet<ActionExercises>(
    `/actions/?organizationRoleId=${organizationRoleId}${
      spaceId ? `&spaceId=${spaceId}` : ''
    }`
  )
}

export async function createAction (body) {
  return await apiPost('/actions', body)
}

export async function updateAction ({
  id,
  status,
  description,
  organizationRoleId,
  dueBy,
  spaceId
}) {
  return await apiPut(`/actions/${id}`, {
    status,
    description,
    organizationRoleId,
    dueBy,
    spaceId
  })
}

export async function deleteAction ({
  id,
  organizationRoleId,
  uid,
  spaceId
}: {
  id: number;
  organizationRoleId: number;
  uid?: string;
  spaceId?: number;
}) {
  return await apiDelete(`/actions/${id}`, {
    organizationRoleId,
    uid,
    spaceId
  })
}

export async function updateConnectionAttendance ({
  connectionId,
  uid,
  organizationRoleId,
  spaceId
}) {
  return await apiPost('/connection_attendance', {
    connectionId,
    uid,
    organizationRoleId,
    spaceId
  })
}

export async function deleteConnectionAttendance ({
  connectionId,
  uid,
  organizationRoleId,
  spaceId
}) {
  return await apiDelete('/connection_attendance', {
    connectionId,
    uid,
    organizationRoleId,
    spaceId
  })
}

export async function getOrganizationRoleDailyCheckins () {
  return apiGet<Checkin[]>('/checkin')
}

export async function createDailyCheckin (checkinResponses) {
  return await apiPost('/checkin/progress', { checkinResponses })
}

export async function createOrUpdateCheckin (checkinResponses) {
  return await apiPut('/checkin/progress', { checkinResponses })
}

export async function putDailycheckins (checkins) {
  return await apiPut('/checkin', { checkins })
}

export async function getOrganizationRoleWithDailyCheckin () {
  return await apiGet<OrganizationRole[]>('/checkin/access')
}

export async function updateOrganizationRoleDailyCheckinAccess (
  organizationRoles
) {
  return await apiPut('/checkin/access', { organizationRoles })
}

export async function updateOrganizationCourse ({
  id,
  access,
  enabled,
  requiresReview,
  actionsApproved
}) {
  return await apiPut(`/organization_courses/${id}`, {
    access,
    enabled,
    requiresReview,
    actionsApproved
  })
}

export async function updateOrganizationRoleOrganizationCourseAccess ({
  organizationId,
  body
}) {
  return await apiPut(
    `/communities/${organizationId}/courses/organization_role`,
    body
  )
}

export async function getCoursePresitge (organizationRoleId: number) {
  return await apiGet(
    `/course_prestige?organizationRoleId=${organizationRoleId}`
  )
}

export async function putCoursePresitge ({
  organizationRoleId,
  courseVersionId
}) {
  return await apiPut('/course_prestige', {
    organizationRoleId,
    courseVersionId
  })
}

export async function getFreedomCheckConnection (id) {
  return await apiGet(`/freedom_check_connection/${id}`)
}

export async function getExercisesActionsForApproval (courseVersionId) {
  return await apiGet(`/exercises/action_approval/${courseVersionId}`)
}

export async function claimInviteCode (inviteCode: string) {
  return await apiPost<{ organizationId?: number }>(`/invite/${inviteCode}/claim`)
}

export async function getOrganizationTags () {
  return await apiGet('/organziation_tags')
}

export async function putContactTag ({ contactId, names }) {
  return await apiPut(`/contacts/${contactId}/tags`, { names })
}
