import { useCreateConnectMutation, useQueryClient, useUpdateConnectionMutation } from '../../shared/queryHooks'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useModal } from '../../context/ModalContext'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { Connection, Space } from '../../types'
import { parseDefaultedDate } from '../../shared/dates'
import { Entity } from '../../shared/enums'

interface StartOrScheduleConnectionProps {
  space?: Space | null
  connection?: Connection
}

function StartOrScheduleConnection ({ space, connection }: StartOrScheduleConnectionProps) {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const createConnect = useCreateConnectMutation()
  const updateConnect = useUpdateConnectionMutation()

  const [typeScheduleConnect, setTypeScheduleConnect] = useState(!!connection?.startTime || false)
  const [meetingLocation, setMeetingLocation] = useState(space?.location || connection?.location || '')
  const [connectionStartTime, setConnectionStartTime] = useState<Date>(parseDefaultedDate(connection?.startTime))
  const [errorMessage, setErrorMessage] = useState('')

  const connectTypeButtonText = typeScheduleConnect ? 'Schedule' : 'Start Now'

  async function handleStartOrSchedule () {
    if (typeScheduleConnect && connectionStartTime === null) {
      setErrorMessage('Please select a start time for the connection.')
      return
    }

    if (connection) {
      const rescheduleConnect = await updateConnect.mutateAsync({ location: meetingLocation, startTime: connectionStartTime, connectionUid: connection?.uid })
      await handleNavigation(rescheduleConnect)
    } else {
      const newConnect = await createConnect.mutateAsync({ location: meetingLocation, startTime: connectionStartTime, spaceId: space?.id })
      await handleNavigation(newConnect)
    }
  }

  async function handleNavigation (createdOrUpdatedConnection) {
    await queryClient.invalidateQueries(['spaces'])
    clearModal()
    if (!typeScheduleConnect) {
      navigate(`/connections/${createdOrUpdatedConnection?.uid}`)
    }
  }

  function handleDateChange (date) {
    setConnectionStartTime(date)
    setErrorMessage('')
  }

  function handleSetConnectionTypeChange (type) {
    setTypeScheduleConnect(type)
    if (!type) {
      setConnectionStartTime(new Date())
    }
    setErrorMessage('')
  }

  return (
    <>
      {errorMessage && <div className="create-connection-error">{errorMessage}</div>}
      <div className="connect-type-selection-container">
        <div className={typeScheduleConnect ? 'connect-type-selection' : 'connect-type-selection-selected'} onClick={() => handleSetConnectionTypeChange(false)}>
          <div className="live-icon">LIVE</div>
          <div className="selection-title">Start Now</div>
        </div>
        <div className={typeScheduleConnect ? 'connect-type-selection-selected' : 'connect-type-selection'} onClick={() => handleSetConnectionTypeChange(true)}>
          <div className="watch-icon">
            <WatchLaterIcon/>
          </div>
          <div className="selection-title">Start Later</div>
        </div>
      </div>
      {typeScheduleConnect && <div className="connect-metaData">
        <label>Future {Entity.Event} start time</label>
        <DatePicker
          selected={connectionStartTime}
          placeholderText="Schedule date and time..."
          onChange={(date) => handleDateChange(date)}
          showTimeSelect
          showMonthDropdown
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="MMMM d, yyyy h:mm aa"
          minDate={new Date()}
        />
      </div>}
      <div className="connect-metaData">
        <label>Where are you meeting?</label>
        <input
          value={meetingLocation}
          onChange={(e) => setMeetingLocation(e.target.value)}
          placeholder='Location, video call link, etc...'
        />
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>Cancel</div>
        <div className="button" data-testid="modal-end-connection-button" onClick={handleStartOrSchedule}>{connectTypeButtonText}</div>
      </div>
    </>
  )
}

export default StartOrScheduleConnection
