import { useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import { useOutsideAlerter } from '../../shared/utilities'
import {
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  Divider
} from '@mui/material'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import Avatar from '../Avatar'

interface ProfileSelectionMenuProps {
  variant: 'public' | 'private';
}

export function ProfileSelectionMenu ({ variant }: ProfileSelectionMenuProps) {
  const navigate = useNavigate()
  const [select, setSelect] = useState(false)
  const wrapperRef = useRef(null)
  const { currentUser, signOutUser } = useAuthContext()
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setSelect })

  const navigateAndClose = (path) => {
    setSelect(false)
    navigate(path)
  }

  const showProfileDropdown = variant === 'private'

  return (
    <div
      ref={wrapperRef}
      style={{ width: '100%' }}
      className={variant === 'public' ? 'public' : ''}
    >
      <div className="nav-title nav-profile" onClick={() => setSelect(!select)}>
        <Avatar
          className="nav-avatar"
          data-testid="navbar-avatar"
          firstName={currentUser?.firstName}
          lastName={currentUser?.lastName}
          avatar={currentUser?.avatar}
        />
        {variant === 'private' && (
          <div className="nav-profile-name" data-testid="navbar-username">
            {currentUser?.firstName}
          </div>
        )}
      </div>
      {select && (
        <div className="profile-dropdown">
          <Paper sx={{ width: 250 }} elevation={24}>
            <MenuList dense data-testid="organization-menu">
              {showProfileDropdown && (
                <>
                  <MenuItem
                    onClick={() => navigateAndClose('/profile/settings')}
                  >
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  <Divider />
                </>
              )}
              <MenuItem onClick={signOutUser}>
                <ListItemText>Sign Out</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </div>
      )}
    </div>
  )
}
