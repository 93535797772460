import { useEditSpaceMutation, usePeopleBySpaceQuery, usePeopleQuery, useQueryClient } from '../../shared/queryHooks'
import { getCurrentOrganizationRole } from '../../shared/utilities'
import { useState } from 'react'
import { useModal } from '../../context/ModalContext'
import { useAuthContext } from '../../context/AuthContext'
import LoadingScreen from '../../components/LoadingScreen'
import PersonItem from '../../components/PersonItem'
import { Space } from '../../types'
import './index.css'

interface AddOrEditSpacerolesProps {
  existingSpace: Space
  created?: boolean
  showAllMembers?: boolean
}

function ManageSpaceRoles ({ existingSpace, created = false, showAllMembers }: AddOrEditSpacerolesProps) {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  const { currentUser } = useAuthContext()
  const [space, setSpace] = useState<Space>(existingSpace)
  const [search, setSearch] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const editSpace = useEditSpaceMutation()

  const { isLoading, data: unfilteredPeople } = space
    ? usePeopleBySpaceQuery(currentUser?.currentOrganizationId ?? 0, space.id, { enabled: !!currentUser })
    : usePeopleQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })
  const people = unfilteredPeople?.filter((person) => person?.status === 'ACTIVE')

  if (isLoading) {
    return <LoadingScreen />
  }

  const searchTokens = (search) => {
    return search
      .toLowerCase()
      .split('')
      .map((char, index) => {
        return (
          (index === 0 && char) || (index === 1 && search.slice(0, 2)) || (index > 2 && search.slice(index, index + 3))
        )
      })
  }

  const getSortedPeopleCards = () => {
    const filteredPeople = people?.filter((person) => {
      if (`${person?.contact?.firstName} ${person?.contact?.lastName}`.toLowerCase().includes(search.toLowerCase())) {
        return true
      }
      return false
    })

    return relevantPeople(filteredPeople, search)
  }

  const relevantPeople = (filterPeople, search) => {
    const scoredPeople = filterPeople?.map((person) => {
      const fullName = `${person?.contact?.firstName} ${person?.contact?.lastName}`.toLowerCase()
      const searchAsLower = search.toLowerCase()

      let score = 0
      if (fullName === searchAsLower) {
        score += 100
      }
      if (fullName.includes(searchAsLower)) {
        score += 50
      }
      for (const token of searchTokens(searchAsLower)) {
        if (fullName.includes(token)) {
          score++
        }
      }
      return { ...person, score }
    })

    return sortRelevantPeople(scoredPeople)
  }

  const sortRelevantPeople = (people) => {
    const sortedPeople = people
      .filter((person) => {
        return person.score >= 1
      })
      .sort((a, b) => {
        return b.score - a.score
      })

    return searchedPeople(sortedPeople)
  }

  const searchedPeople = (sortedPeople) => {
    if (sortedPeople?.length) {
      return sortedPeople?.map((person) => (
        <PersonItem key={person.id} person={person} space={space} setSpace={setSpace} />
      ))
    }
    return <div className="no-member-message">No members found</div>
  }

  const createdOrSkipButton =
    space?.spaceRoles && space.spaceRoles.length > 1
      ? (
      <button className="button" onClick={() => editSpace.mutate(space)}>
        Add Members
      </button>
        )
      : (
      <button className="button" onClick={() => clearModal()}>
        Skip
      </button>
        )

  function isInitialCreated (people) {
    if (created) {
      return people?.filter((person) => person?.id !== getCurrentOrganizationRole(currentUser)?.id)
    }
    return people
  }

  function getPeopleCards () {
    const cards = isInitialCreated(people)
      ?.filter((person) => space?.spaceRoles?.find((spaceRole) => spaceRole?.organizationRoleId === person?.id))
      .map((person) => <PersonItem key={person?.id} person={person} space={space} setSpace={setSpace} />)
    return <div>{cards}</div>
  }

  function hasSpaceLeader (submittedSpaceRoles) {
    return submittedSpaceRoles?.some((role) => role?.title === 'LEADER')
  }

  async function handleSubmit (space) {
    if (!hasSpaceLeader(space?.spaceRoles)) {
      setErrorMessage('A space must have a leader. Please add one and try again.')
      return
    }
    setErrorMessage('')
    await editSpace.mutateAsync(space)
    await queryClient.invalidateQueries([`organization:${currentUser?.currentOrganizationId}`, 'spaces'])
  }

  return (
    <div>
      {errorMessage && <div className="claim-error">{errorMessage}</div>}
      <p style={{ marginBottom: 0 }}>Add members to space</p>
      <div
        className="modal-header"
        style={{
          padding: '0',
          top: '3rem',
          paddingTop: '0.5rem'
        }}
      >
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          data-testid="search"
        />
      </div>
      <div className="person-results">
        {showAllMembers
          ? (
          <>{search ? getSortedPeopleCards() : getPeopleCards()}</>
            )
          : (
          <>{search && getSortedPeopleCards()}</>
            )}
      </div>
      <div className="modal-footer">
        {!created
          ? (
          <button className="button" onClick={() => handleSubmit(space)}>
            Save
          </button>
            )
          : (
              createdOrSkipButton
            )}
      </div>
    </div>
  )
}

export default ManageSpaceRoles
