import {
  useCreateExerciseProgressMutation,
  useDeleteExerciseProgressMutation,
  useCreateActionMutation,
  useUpdateActionMutation,
  useDeleteActionMutation
} from '../../shared/queryHooks'
import { getUserActions } from '../../shared/api'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import { isAuthorized } from '../../shared/permissions'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import LoadingScreen from '../LoadingScreen'
import { useRef, forwardRef, useState, useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DatePicker from 'react-datepicker'
import {
  DisplayState,
  OutsideAlerterArgs,
  getCurrentOrganizationRole,
  isYouTubeUrl,
  isVimeoUrl
} from '../../shared/utilities'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { Exercise, User } from '../../types'

interface ListExercisesItemProps {
  currentUser: User | null;
  exercise?: Exercise | null;
  userId?: number;
  organizationRoleId?: number;
  connectionId?: number;
  readOnlyCompleted?: boolean;
  spaceId?: number;
  connectionUid?: string | null;
  readOnly?: boolean;
  exerciseStatus?: string;
  useOutsideAlerter?: (props: OutsideAlerterArgs) => void;
  scheduleExercise?: number | null;
  setScheduleExercise?: DisplayState<number | null>;
  dueBy?: Date | null;
  isAction?: boolean;
  actionId?: number | null;
}

function ListExercisesItem ({
  currentUser,
  exercise,
  userId,
  organizationRoleId,
  connectionId,
  readOnlyCompleted = false,
  spaceId,
  connectionUid,
  readOnly = false,
  exerciseStatus,
  useOutsideAlerter,
  scheduleExercise,
  setScheduleExercise,
  dueBy,
  isAction = false,
  actionId
}: ListExercisesItemProps) {
  const createExerciseProgress = useCreateExerciseProgressMutation()
  const deleteExerciseProgress = useDeleteExerciseProgressMutation()
  const createAction = useCreateActionMutation()
  const updateAction = useUpdateActionMutation()
  const deleteAction = useDeleteActionMutation()
  const [isComplete, setIsComplete] = useState<boolean>(
    exercise?.isComplete ?? false
  )
  const [exerciseStatusState, setExerciseStatusState] = useState<string>(
    exerciseStatus ?? 'uncompleted'
  )
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const isMemberOrGuest =
    currentOrganizationRole?.title === 'MEMBER' ||
    currentOrganizationRole?.title === 'GUEST'
  const dueByWrapperRef = useRef(null)
  if (
    setScheduleExercise &&
    useOutsideAlerter &&
    typeof useOutsideAlerter === 'function'
  ) {
    useOutsideAlerter({
      ref: dueByWrapperRef,
      setDisplayState: setScheduleExercise
    })
  }

  useEffect(() => {
    setIsComplete(exercise?.isComplete ?? false)
  }, [exercise?.isComplete])

  useEffect(() => {
    setExerciseStatusState(exerciseStatus ?? 'uncompleted')
  }, [exerciseStatus])

  const toggleIsComplete = () => {
    if (!readOnlyCompleted) {
      if (isComplete) {
        deleteExerciseProgress.mutate({
          exerciseProgressId: exercise?.exerciseProgressId,
          spaceId,
          organizationRoleId,
          userId
        })
        setIsComplete(false)
      } else {
        createExerciseProgress.mutate({
          spaceId,
          exerciseId: exercise?.id,
          connectionId,
          userId,
          organizationRoleId
        })
        setIsComplete(true)
      }
    }
  }

  const isEditable = () => {
    if (connectionId) {
      return isAuthorized(
        getSpaceLeaderPolicy(spaceId, currentUser?.currentOrganizationId),
        currentUser
      )
    } else {
      return false
    }
  }

  async function removeExerciseAction () {
    const userActions = await getUserActions({ organizationRoleId, spaceId })
    const id = userActions?.actions?.filter(
      (action) =>
        action?.actionType === 'EXERCISE' &&
        action?.description === exercise?.id.toString()
    )?.[0]?.id
    deleteAction.mutate({
      id,
      uid: connectionUid ?? '',
      spaceId,
      organizationRoleId: organizationRoleId ?? 0
    })
  }

  const CustomInputPicker = forwardRef<any, any>(({ value, onClick }, ref) => {
    return (
      <div className="custom-date-picker-input" onClick={onClick} ref={ref}>
        <div className="custom-date-picker-input-value">{value}</div>
        <div className="exercise-down-arrow-icon">
          <KeyboardArrowDownIcon />
        </div>
      </div>
    )
  })

  const scheduleExerciseCompletionDate = ({ date, exerciseId }) => {
    if (
      exerciseStatusState === 'scheduled' ||
      exerciseStatusState === 'readyForReview'
    ) {
      return updateAction.mutateAsync({
        dueBy: date,
        description: exerciseId?.toString(),
        organizationRoleId,
        status: 'UNCOMPLETED',
        id: actionId,
        spaceId: undefined
      })
    }

    createAction.mutate({
      dueBy: date,
      description: exerciseId?.toString(),
      uid: connectionUid,
      organizationRoleId,
      actionType: 'EXERCISE',
      organizationId: currentUser?.currentOrganizationId
    })
    setExerciseStatusState('scheduled')
    if (setScheduleExercise && typeof setScheduleExercise === 'function') {
      setScheduleExercise(null)
    }
  }

  if (currentUser) {
    return (
      <div
        className={`exercise-list-item ${isComplete && 'complete'} ${
          readOnlyCompleted ? 'complete-readonly' : ''
        } ${
          exerciseStatusState === 'scheduled' && !isComplete && 'scheduled'
        } ${
          exerciseStatusState === 'readyForReview' &&
          !isComplete &&
          'readyForReview'
        } ${isAction && 'action-exercise-list-item'}`}
      >
        <div className="exercise-checkbox-container" onClick={toggleIsComplete}>
          {isEditable() && !readOnly && (
            <div className="exercise-checkbox">
              {(isComplete || readOnlyCompleted) && <CheckRoundedIcon />}
            </div>
          )}
        </div>
        <div className="exercise-details-container">
          <div className="exercise-metadata">
            <div className="exercise-title">{exercise?.title}</div>
            <div className="exercise-parent-title">
              {exercise?.section?.title}
            </div>
          </div>
          <div className="exercise-actions">
            {!isComplete && !readOnly && (
              <div>
                <div className="exercise-schedule-icon">
                  <ScheduleRoundedIcon
                    style={{ right: '10.6rem' }}
                    onClick={() => {
                      if (
                        setScheduleExercise &&
                        typeof setScheduleExercise === 'function'
                      ) {
                        setScheduleExercise(
                          scheduleExercise === exercise?.id
                            ? null
                            : exercise?.id ?? null
                        )
                      }
                    }}
                  />
                </div>
                <div style={{ position: 'relative' }}>
                  {scheduleExercise === exercise?.id && (
                    <div
                      className="exercise-action-dueBy-dropdown"
                      ref={dueByWrapperRef}
                    >
                      <DatePicker
                        selected={dueBy || new Date()}
                        placeholderText="Due by..."
                        onChange={(date) =>
                          scheduleExerciseCompletionDate({
                            date,
                            exerciseId: exercise?.id
                          })
                        }
                        showMonthDropdown
                        dateFormat="MM/d/yyyy"
                        minDate={new Date()}
                        customInput={<CustomInputPicker />}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {exerciseStatusState === 'scheduled' &&
              !isComplete &&
              !readOnly &&
              !isMemberOrGuest && (
                <div
                  onClick={removeExerciseAction}
                  className="exercise-schedule-icon remove-schedule-icon"
                >
                  <CloseRoundedIcon style={{ right: '10.6rem' }} />
                </div>
            )}
            {exercise?.url && (
              <div className="exercise-link">
                {((!isYouTubeUrl(exercise.url) && !isVimeoUrl(exercise.url)) ||
                  connectionId) && (
                  <a href={exercise.url} target="_blank" rel="noreferrer">
                    <OpenInNewRoundedIcon />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
  return <LoadingScreen />
}

export default ListExercisesItem
