import { useQueryClient } from '@tanstack/react-query'
import { Crisp } from 'crisp-sdk-web'
import { roles } from './enums'
import currency from 'currency.js'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { Connection, Space, User } from '../types'
import { Feature } from './features'
import { isFeatureEnabled } from './posthog'
import { currentDateTime, parseDateTime } from './dates'
import { LAST_ORGANIZATION_ID_KEY } from '../context/AuthContext'

const formatDateString = (timestamp) => {
  if (!timestamp) return
  const date = new Date(timestamp)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  return date.toLocaleDateString('en-us', options)
}

const formatConnectionDate = (timestamp) => {
  if (!timestamp) return
  const time = new Date(timestamp)
  return format(time, 'E LLL d, h:mm a')
}

export const formatReportDate = (timestamp) => {
  if (!timestamp) return
  const time = new Date(timestamp)
  return format(time, 'E LLL d h:mm a')
}

const sortSpaceRolesByFirstName = (roleA, roleB) => {
  const nameA = roleA.organizationRole?.contact?.firstName.toUpperCase()
  const nameB = roleB.organizationRole?.contact?.firstName.toUpperCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

const sortSpaceRolesByRoleAndFirstName = (roleA, roleB) => {
  const isALeader = roleA?.title === 'LEADER'
  const isBLeader = roleB?.title === 'LEADER'

  if (isALeader && !isBLeader) return -1
  if (!isALeader && isBLeader) return 1

  const nameA = roleA.organizationRole?.contact?.firstName.toUpperCase()
  const nameB = roleB.organizationRole?.contact?.firstName.toUpperCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

const sortUsersByStatusAndName = (userA, userB) => {
  const nameA = userA?.contact?.firstName.toUpperCase()
  const nameB = userB?.contact?.firstName.toUpperCase()
  const statusA = userA?.status
  const statusB = userB?.status

  return statusA?.localeCompare(statusB) || nameA?.localeCompare(nameB)
}

const sortProgressEntryByExerciseId = (progressEntryA, progressEntryB) => {
  if (progressEntryA?.exercise?.id < progressEntryB?.exercise?.id) return -1
  if (progressEntryB?.exercise?.id > progressEntryA?.exercise?.id) return 1
  return 0
}

const sortSpacesByName = (spaceA: Space, spaceB: Space) => {
  if (spaceA.name.localeCompare(spaceB.name) < 0) return -1
  if (spaceA.name.localeCompare(spaceB.name) > 0) return 1
  return 0
}

function pluralize (string, length) {
  return `${string}${length === 1 ? '' : 's'}`
}

function getOrganizationsAsAdmin (currentUser?: User | null) {
  const allowedRoles = [roles.owner, roles.admin]
  return (
    currentUser?.organizationRoles
      ?.filter(
        (orgRole) =>
          allowedRoles.includes(orgRole.title) && orgRole.status === 'ACTIVE'
      )
      .map((orgRole) => orgRole?.organization) || []
  )
}

function getActiveOrganizations (currentUser?: User | null) {
  return (
    currentUser?.organizationRoles
      ?.filter((orgRole) => orgRole.status === 'ACTIVE')
      ?.map((orgRole) => orgRole.organization) ?? []
  )
}

function getCurrentOrganization (currentUser?: User | null) {
  const { currentOrganizationId } = currentUser || {}
  return (
    currentUser?.organizationRoles?.find(
      (orgRole) => orgRole?.organization?.id === currentOrganizationId
    )?.organization || null
  )
}

function isNewUserWithOrganization (currentUser?: User | null) {
  return (
    currentUser?.currentOrganizationId &&
    currentUser?.organizationRoles?.length === 0
  )
}

function useSwitchOrganization () {
  const queryClient = useQueryClient()
  return {
    switchOrganization: (organizationId: string) => {
      console.info('Switching organization to', organizationId)
      localStorage.setItem(LAST_ORGANIZATION_ID_KEY, organizationId)
      switchOrganization(queryClient, organizationId)
    }
  }
}

function switchOrganization (queryClient, organizationId) {
  const oldUser = queryClient.getQueryData(['user'])
  queryClient.setQueryData(['user'], {
    ...oldUser,
    currentOrganizationId: organizationId
  })
  queryClient.invalidateQueries()
}

function isEmpty (string) {
  return string.trim() === ''
}

function getCourseUidFromSearchParams (params) {
  return decodeURIComponent(params.get('course') || '')
}

const getCurrentOrganizationRole = (currentUser?: User | null) => {
  return currentUser?.organizationRoles?.find(
    (orgRole) => orgRole.organization?.id === currentUser.currentOrganizationId
  )
}

function formatPhoneNumberInput (phoneInput) {
  if (!phoneInput) return phoneInput
  const phoneNumber = phoneInput.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

function formatReadOnlyPhoneNumber (phoneNumber) {
  return `(${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
    5,
    8
  )}-${phoneNumber.slice(8, 12)}`
}

function loadScript (url, callback) {
  const scriptElements = document.querySelectorAll(
    "script[src*='".concat(url, "']")
  )

  if (scriptElements?.length === 0) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onload = () => callback()
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  } else {
    callback()
  }
}

function formatAmountAsDollars (amountInCents) {
  return currency(amountInCents, { fromCents: true }).format()
}

function openSupportChat () {
  try {
    Crisp.chat.show()
    Crisp.chat.open()
  } catch (error) {
    window.location.href = 'mailto:support@connectbetter.io'
  }
}

function isUrl (url) {
  if (!url) return false
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(url)
}

function canCreateOrganization () {
  return isFeatureEnabled(Feature.CreateOrganization)
}

function sortOrgCourses (courses) {
  courses?.sort((a, b) => {
    if (a?.id !== b?.id) {
      return a?.id - b?.id
    } else {
      return b?.courseVersion?.version - a?.courseVersion?.version
    }
  })
  return courses
}

const actionTypeOrder = {
  DAILY_CHECKIN: 0,
  CONNECT: 1,
  TASK: 2,
  EXERCISE: 3
}

const sortByActionType = (a, b) => {
  const aType = a?.actionType || ''
  const bType = b?.actionType || ''

  if (actionTypeOrder[aType] < actionTypeOrder[bType]) {
    return -1
  } else if (actionTypeOrder[aType] > actionTypeOrder[bType]) {
    return 1
  }

  const aDueBy = new Date(a?.dueBy)?.getTime()
  const bDueBy = new Date(b?.dueBy)?.getTime()

  return aDueBy - bDueBy
}

const sortByCompletedAction = (a, b) => {
  const aDueBy = new Date(a?.updatedAt)?.getTime()
  const bDueBy = new Date(b?.updatedAt)?.getTime()

  return bDueBy - aDueBy
}

const searchTokens = (search) => {
  return search
    .toLowerCase()
    .split('')
    .map((char, index) => {
      return (
        (index === 0 && char) ||
        (index === 1 && search.slice(0, 2)) ||
        (index > 2 && search.slice(index, index + 3))
      )
    })
}

const getSortedContent = (
  searchParms,
  searchItemDisplay,
  contents,
  filter?
) => {
  const filteredContents = contents?.filter((content) => {
    if (
      `${filter ? `${content?.firstName}${content?.lastName}` : content?.name}`
        .toLowerCase()
        .includes(searchParms.toLowerCase())
    ) {
      return true
    }
    return false
  })

  return relevantContent(
    filteredContents,
    searchParms,
    searchItemDisplay,
    filter
  )
}

const relevantContent = (
  filteredContents,
  searchParms,
  searchItemDisplay,
  filter
) => {
  const scoredContents = filteredContents?.map((content) => {
    const name = `${
      filter ? `${content?.firstName}${content?.lastName}` : content?.name
    }`.toLowerCase()
    const searchAsLower = searchParms.toLowerCase()

    let score = 0
    if (name === searchAsLower) {
      score += 100
    }
    if (name.includes(searchAsLower)) {
      score += 50
    }
    for (const token of searchTokens(searchAsLower)) {
      if (name.includes(token)) {
        score++
      }
    }
    return { ...content, score }
  })

  return sortRelevantContent(scoredContents, searchItemDisplay)
}

const sortRelevantContent = (contents, searchItemDisplay) => {
  const sortedContents = contents
    .filter((content) => {
      return content.score >= 1
    })
    .sort((a, b) => {
      return b.score - a.score
    })

  return searchedContent(sortedContents, searchItemDisplay)
}

const searchedContent = (sortedContents, searchItemDisplay) => {
  if (sortedContents?.length) {
    return sortedContents?.map((content) => searchItemDisplay(content))
  }
  return <div className="no-items-found">No items found</div>
}

export type DisplayState<T> = React.Dispatch<React.SetStateAction<T>>;

export type OutsideAlerterArgs = {
  ref: React.MutableRefObject<any>;
  setDisplayState: DisplayState<any>;
};

function useOutsideAlerter ({ ref, setDisplayState }: OutsideAlerterArgs) {
  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayState(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const getRandomString = () => {
  return Math.random().toString(36).substring(2, 15)
}

const generateCSRF = () => {
  const random = `${getRandomString()}${getRandomString()}`
  localStorage.setItem('csrf', random)
  return random
}

export const isConnectLive = (connect: Connection) => {
  return (
    !connect?.endTime && currentDateTime() > parseDateTime(connect?.startTime)
  )
}

export const arraysMatch = <T, >(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

function isYouTubeUrl (url: string = '') {
  return url.includes('youtube.com/watch') || url.includes('youtu.be')
}

function getYouTubeVideoId (url: string = '') {
  const videoIdMatch = url.match(
    /(?:youtube\.com\/(?:[^/]+\/[^/]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/
  )
  return videoIdMatch ? videoIdMatch[1] : null
}

function isVimeoUrl (url: string = '') {
  return url.includes('vimeo.com/')
}

function getVimeoVideoId (url: string = '') {
  const videoIdMatch = url.match(/vimeo\.com\/(?:video\/)?(\d+)/)
  return videoIdMatch ? videoIdMatch[1] : null
}

export {
  sortSpaceRolesByFirstName,
  formatDateString,
  formatConnectionDate,
  sortSpacesByName as sortSpacesByMostPeople,
  sortUsersByStatusAndName,
  pluralize,
  getOrganizationsAsAdmin,
  useSwitchOrganization,
  getActiveOrganizations,
  isNewUserWithOrganization,
  getCourseUidFromSearchParams,
  isEmpty,
  getCurrentOrganizationRole,
  getCurrentOrganization,
  sortProgressEntryByExerciseId,
  formatReadOnlyPhoneNumber,
  formatPhoneNumberInput,
  loadScript,
  openSupportChat,
  formatAmountAsDollars,
  isUrl,
  canCreateOrganization,
  sortSpaceRolesByRoleAndFirstName,
  sortOrgCourses,
  sortByActionType,
  sortByCompletedAction,
  getSortedContent,
  useOutsideAlerter,
  generateCSRF,
  isYouTubeUrl,
  getYouTubeVideoId,
  isVimeoUrl,
  getVimeoVideoId
}
