import { useUpdateOrganizationAndCreateSubscriptionMutation } from '../../shared/queryHooks'
import { Alert, AlertTitle } from '@mui/material'
import { getCurrentOrganization, formatAmountAsDollars } from '../../shared/utilities'
import { useModal } from '../../context/ModalContext'
import { useEffect, useState } from 'react'
import { getOrganizationSubscription } from '../../shared/api'
import { useAuthContext } from '../../context/AuthContext'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { logWarn } from '../../shared/logger'
import { useQueryClient } from '@tanstack/react-query'
import { PuffLoader } from 'react-spinners'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import OrganizationSelectionMenu from '../../components/OrganizationSelectionMenu'
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded'
import Button from '../../components/Button'
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import './index.css'
import { CreatePaymentMethodData } from '@stripe/stripe-js'
import { Entity } from '../../shared/enums'

function ActivateOrganization () {
  const [stripeFontColor, setStripeFontColor] = useState('#232832')

  useEffect(() => {
    const query = window.matchMedia('(prefers-color-scheme: dark)')
    const handleThemeChange = (e) => {
      setStripeFontColor(e.matches ? '#ffffff' : '#232832')
    }
    setStripeFontColor(query.matches ? '#ffffff' : '#232832')
    query.addEventListener('change', handleThemeChange)
    return () => query.removeEventListener('change', handleThemeChange)
  }, [])

  const { signOutUser, currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const queryClient = useQueryClient()
  const { setTitle } = useModal()
  const stripe = useStripe()
  const elements = useElements()
  const unfreezeAndCreateSubscription = useUpdateOrganizationAndCreateSubscriptionMutation()
  const [paymentMethod, setPaymentMethod] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [orgSubscription, setOrgSubscription] = useState<any>(null)

  const isAdminOrOwner = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)

  useEffect(() => {
    setTitle(<OrganizationSelectionMenu onlyOrganizations className="activate-organization-menu" />)
    if (isAdminOrOwner) {
      void getSubscription()
    }
  }, [])

  const getSubscription = async () => {
    const subscription = await queryClient.fetchQuery(['organizations', currentUser?.currentOrganizationId, 'subscription'], () => getOrganizationSubscription(currentUser?.currentOrganizationId))
    setOrgSubscription(subscription)
  }

  const submitPaymentMethod = async () => {
    if (!stripe || !elements) {
      setErrorMessage('Error processing your card information, please try again.')
      return false
    }

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${currentUser?.firstName} ${currentUser?.lastName}`
        }
      } as CreatePaymentMethodData)
      if (error) {
        throw Error(error.message)
      }
      await unfreezeAndCreateSubscription.mutateAsync({ organizationId: currentUser?.currentOrganizationId, paymentMethodId: paymentMethod?.id, customerId: currentOrganization?.stripeCustomerId })
      setErrorMessage('')
    } catch (error) {
      logWarn(error)
      setErrorMessage('Error adding your card, please confirm your details and try again.')
    }
  }

  return (
    <div className="activate-organization-modal">
      {paymentMethod
        ? <>
            {errorMessage && <div className="claim-error">{errorMessage}</div>}
            <Alert icon={<AccountBalanceIcon />} severity="info">
            <AlertTitle>Account Balance</AlertTitle>
              Your monthly subscription amount is {formatAmountAsDollars(orgSubscription?.nextSubscriptionAmount)} and will be charged upon a payment method being provided.
            </Alert>
            <div className="payment-container">
              <label htmlFor="payment-method">Card</label>
              <div className="element-container">
                <CardElement id="payment-method" options={{
                  style: {
                    base: {
                      color: stripeFontColor,
                      fontFamily: '"Rubik", sans-serif'
                    }
                  }
                }} />
              </div>
            </div>
            <div className="stripe-partnership"><ShieldRoundedIcon /> Securely processed using Stripe</div>
            <div className="modal-footer">
              <Button className="button secondary" onClick={() => setPaymentMethod(false)}>Cancel</Button>
              <Button onClick={() => submitPaymentMethod()}>{unfreezeAndCreateSubscription.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Restart subscription'}</Button>
            </div>
          </>
        : <>
            <Alert icon={<AcUnitRoundedIcon />} severity="info">
            <AlertTitle>{Entity.Community} Frozen</AlertTitle>
              {isAdminOrOwner
                ? `Your ${Entity.Community} is frozen, please add a valid payment method to restart your subscription and unfreeze your ${Entity.Community}.`
                : `Your ${Entity.Community} is frozen, please contact your admin to unfreeze your ${Entity.Community}.`
              }
            </Alert>
            <div className="modal-footer">
              <Button className="button secondary" onClick={signOutUser}>Sign out</Button>
              {isAdminOrOwner && <Button onClick={() => setPaymentMethod(true)}>Fix payment method</Button>}
            </div>
          </>
        }
    </div>
  )
}

export default ActivateOrganization
