import { useAuthContext } from '../../context/AuthContext'
import { getCurrentOrganizationRole } from '../../shared/utilities'
import { useActionsQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationPaidMemberPolicy } from '../../shared/policies'
import { useModal } from '../../context/ModalContext'
import EmptyHero from '../../components/EmptyHero'
import actionsIllustration from '../../assets/actions.svg'
import LoadingScreen from '../../components/LoadingScreen'
import AvailableActions from '../../components/AvailableActions'
import CompletedActions from '../../components/CompletedActions'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import CreateOrEditAction from '../../modals/CreateOrEditAction'
import { useState } from 'react'

function ActionsPage () {
  const { currentUser } = useAuthContext()
  const { makeModal, clearModal } = useModal()
  const organizationRoleId = getCurrentOrganizationRole(currentUser)?.id
  const isPaidMember = isAuthorized(
    getOrganizationPaidMemberPolicy(currentUser?.currentOrganizationId),
    currentUser
  )
  const {
    isLoading,
    isError,
    data: userActions,
    error
  } = useActionsQuery(organizationRoleId ?? 0, undefined, {
    enabled: !!currentUser && !!organizationRoleId
  })

  const [searchParams, setSearchParams] = useState('')

  if (isError) {
    logError(error)
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  const getSubnavAction = () => {
    const actions: any[] = []

    if (isPaidMember) {
      actions.push({
        action: () =>
          makeModal({
            modal: <CreateOrEditAction clearModal={clearModal} />,
            title: 'Create Action'
          }),
        actionTitle: 'Create Action',
        actionClassName: 'secondary'
      })
    }
    return actions
  }

  const searchTokens = (search) => {
    return search
      .toLowerCase()
      .split('')
      .map((char, index) => {
        return (
          (index === 0 && char) ||
          (index === 1 && search.slice(0, 2)) ||
          (index > 2 && search.slice(index, index + 3))
        )
      })
  }

  const getSortedContent = (searchParms, contents) => {
    const filteredContents = contents?.actions?.filter((content) => {
      const exerciseAction = contents?.actionExercises?.find(
        (action) => action.id === parseInt(content.description)
      )

      if (
        `${content?.description.toLowerCase()}`.includes(
          searchParms.toLowerCase()
        ) ||
        `${exerciseAction?.title.toLowerCase()}`.includes(
          searchParms.toLowerCase()
        )
      ) {
        return true
      }

      return false
    })

    return relevantContent(
      filteredContents,
      searchParms,
      contents?.actionExercises
    )
  }

  const relevantContent = (filteredContents, searchParms, actionExercises) => {
    const scoredContents = filteredContents?.map((content) => {
      const description = `${content?.description}`.toLowerCase()
      const exerciseTitle = actionExercises?.find(
        (action) => action.id === parseInt(content.description)
      )?.title
      const searchAsLower = searchParms.toLowerCase()
      let score = 0
      if (description === searchAsLower || exerciseTitle === searchAsLower) {
        score += 100
      }
      if (
        description.includes(searchAsLower) ||
        exerciseTitle.includes(searchAsLower)
      ) {
        score += 50
      }
      for (const token of searchTokens(searchAsLower)) {
        if (description.includes(token) || exerciseTitle.includes(token)) {
          score++
        }
      }
      return { ...content, score }
    })

    return sortRelevantContent(scoredContents)
  }

  const sortRelevantContent = (contents) => {
    const sortedContents = contents
      .filter((content) => {
        return content.score >= 1
      })
      .sort((a, b) => {
        return b.score - a.score
      })

    return sortedContents
  }

  const searchActions = getSortedContent(searchParams, userActions)

  const actionsDisplay = (
    <>
      <AvailableActions
        actions={searchParams ? searchActions : userActions?.actions}
        organizationRoleId={organizationRoleId}
        actionExercises={userActions?.actionExercises}
      />
      <CompletedActions
        actions={searchParams ? searchActions : userActions?.actions}
        organizationRoleId={organizationRoleId}
        actionExercises={userActions?.actionExercises}
      />
    </>
  )

  const action = [
    {
      onClick: () =>
        makeModal({
          modal: <CreateOrEditAction clearModal={clearModal} />,
          title: 'Create Action'
        }),
      actionTitle: 'Create Action'
    }
  ]

  return (
    <div>
      <SecondaryNavBar title="Actions" onClickActions={getSubnavAction()} />
      {userActions?.actions && userActions.actions.length > 0
        ? (
        <div>
          <div className="spaces-search" style={{ marginBottom: '0.5rem' }}>
            <input
              type="text"
              placeholder="Search Actions..."
              onChange={(e) => setSearchParams(e.target.value)}
              value={searchParams}
            />
          </div>
          {actionsDisplay}
        </div>
          )
        : (
        <EmptyHero
          image={actionsIllustration}
          title="Actions"
          action={action}
          description="Actions are a great way to track your progress and stay accountable. Create an action to get started."
        />
          )}
    </div>
  )
}

export default ActionsPage
