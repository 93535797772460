import './index.css'
import { Link } from 'react-router-dom'
import { formatConnectionDate } from '../../shared/utilities'
import { Connection } from '../../types'
import { Entity } from '../../shared/enums'

interface ConnectionHistoryListProps {
  connections: Connection[]
}

function ConnectionHistoryList ({ connections }: ConnectionHistoryListProps) {
  if (connections?.length === 0) {
    return null
  }

  return (
    <div className="connection-history-item">
      <h3>Past {Entity.Event}s</h3>
      {connections?.map(connection => (
        <Link data-testid={`connection-id-${connection.uid}`} to={`/connections/${connection.uid}`} key={connection.uid}>{formatConnectionDate(connection.startTime)}</Link>
      ))}
    </div>
  )
}

export default ConnectionHistoryList
