import {
  useExerciseListByUserQuery,
  useExerciseListBySpaceQuery
} from '../../shared/queryHooks'
import { useState } from 'react'
import { logError } from '../../shared/logger'
import { useOutsideAlerter } from '../../shared/utilities'
import ListExercisesItem from '../ListExercisesItem'
import emptyIllustration from '../../assets/pathway.svg'
import EmptyHero from '../EmptyHero'
import './index.css'
import { Action, User } from '../../types'
import { parseDate } from '../../shared/dates'
import { Entity } from '../../shared/enums'

interface ListExercisesProps {
  currentUser?: User | null;
  organizationRoleId?: number;
  connectionId?: number;
  spaceId?: number;
  userId?: number;
  courseVersionId?: number;
  connectionUid?: string | null;
  readOnly?: boolean;
  userActions?: Action[] | null;
}

function ListExercises ({
  currentUser,
  organizationRoleId,
  connectionId,
  spaceId,
  userId,
  courseVersionId,
  connectionUid,
  readOnly = false,
  userActions
}: ListExercisesProps) {
  const [showCompletedOnly, setShowCompletedOnly] = useState(false)
  const [scheduleExercise, setScheduleExercise] = useState<number | null>(null)
  const {
    isLoading,
    isError,
    data: exercises,
    error
  } = userId
    ? useExerciseListByUserQuery(organizationRoleId ?? 0, userId ?? 0, {})
    : useExerciseListBySpaceQuery(organizationRoleId ?? 0, spaceId ?? 0, {})

  if (isLoading) {
    return null
  }

  if (isError) {
    logError(error)
  }

  if (exercises?.length === 0) {
    return (
      <EmptyHero
        image={emptyIllustration}
        title="Courses"
        description={`Your pathway is the heart of your connection. You need to ${Entity.Event} with someone before things will appear here.`}
      />
    )
  }

  const filteredExerciseList = () => {
    return exercises?.filter(
      (exercise) => courseVersionId === exercise?.section?.courseVersionId
    )
  }

  const exerciseList = courseVersionId ? filteredExerciseList() : exercises

  const totalExercises = exerciseList ? exerciseList?.length : 0
  const completeExercises =
    exerciseList?.filter((exercise) => exercise?.isComplete === true).length ?? 0

  const percentageOfExercisesComplete =
    totalExercises !== 0
      ? `${Math.round((completeExercises / totalExercises) * 100)}%`
      : '0%'

  const toggleCompletedExercises = () => {
    setShowCompletedOnly((prevView) => !prevView)
  }

  function checkUncompletedOrConnectionCompleted ({ exercise, connectionId }) {
    return (
      exercise?.isComplete === false || connectionId === exercise?.connectionId
    )
  }

  function checkIfCompleted (exercise) {
    return exercise?.isComplete === true
  }

  const toggledExerciseList = exerciseList?.filter((exercise) =>
    showCompletedOnly
      ? checkIfCompleted(exercise)
      : checkUncompletedOrConnectionCompleted({ exercise, connectionId })
  )

  const groupedExercises = toggledExerciseList?.reduce((acc, exercise) => {
    const sectionId = exercise?.section?.id
    const displaySectionHeaders = exercise?.section?.courseVersion?.course?.displaySectionHeaders
    if (!acc[sectionId]) {
      acc[sectionId] = {
        title: exercise?.section?.title,
        exercises: [],
        displaySectionHeaders,
        sortBy: exercise?.section?.sortBy
      }
    }
    acc[sectionId].exercises.push(exercise)
    return acc
  }, {} as Record<string, any>) || {}

  const sortedGroupedExercises = Object.values(groupedExercises).sort((a: any, b: any) => {
    if (a.sortBy === null || a.sortBy === undefined) return 1
    if (b.sortBy === null || b.sortBy === undefined) return -1
    return a.sortBy - b.sortBy
  })

  return (
    <>
      {currentUser?.currentOrganizationId && (
        <div className="exercise-list-container">
          <div className="progress-bar">
            <div
              className="progress-bar-inner"
              style={{ width: percentageOfExercisesComplete }}
            ></div>
          </div>
          <div className="progress-metadata">
            {totalExercises !== 0
              ? (
              <div className="progress-bar-text">
                {percentageOfExercisesComplete} completed so far
              </div>
                )
              : (
              <div className="progress-bar-text">
                No exercises tied to this course
              </div>
                )}
            {totalExercises !== 0 && (
              <div
                className="show-completed-button"
                onClick={toggleCompletedExercises}
              >
                {showCompletedOnly ? 'Hide Completed' : 'Show Completed'}
              </div>
            )}
          </div>
          <div className="exercise-list">
            {sortedGroupedExercises.map((section) => (
              <div key={section?.id}>
                {section?.displaySectionHeaders && (
                  <>
                    <div style={{ fontWeight: '600', marginTop: '0.5rem' }}>{section?.title}</div>
                    <hr />
                  </>
                )}
                {section.exercises.map((exercise) => {
                  const exerciseStatus = userActions?.filter(
                    (action) =>
                      action?.actionType === 'EXERCISE' &&
                      parseInt(action?.description) === exercise?.id &&
                      organizationRoleId === action?.organizationRoleId
                  )

                  return (
                    <ListExercisesItem
                      currentUser={currentUser}
                      key={exercise?.id}
                      organizationRoleId={organizationRoleId}
                      exercise={exercise}
                      connectionId={connectionId}
                      spaceId={spaceId}
                      connectionUid={connectionUid}
                      readOnly={readOnly}
                      useOutsideAlerter={useOutsideAlerter}
                      scheduleExercise={scheduleExercise}
                      setScheduleExercise={setScheduleExercise}
                      exerciseStatus={
                        exerciseStatus && exerciseStatus.length > 0
                          ? exerciseStatus[0]?.status === 'REVIEW'
                            ? 'readyForReview'
                            : 'scheduled'
                          : ''
                      }
                      dueBy={exerciseStatus && parseDate(exerciseStatus[0]?.dueBy)}
                      actionId={userActions && userActions[0]?.id}
                      isAction={false}
                    />
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default ListExercises
