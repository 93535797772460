import { useUpdateActionMutation } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { Checkbox } from '@mui/material'
import { useModal } from '../../context/ModalContext'
import { isAuthorized } from '../../shared/permissions'
import { getSpaceLeaderPolicy, getSpacePolicy } from '../../shared/policies'
import { formatConnectionDate, isYouTubeUrl, isVimeoUrl, getYouTubeVideoId, getVimeoVideoId } from '../../shared/utilities'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ActionSettingsMenu from '../ActionSettingsMenu'
import DailyCheckin from '../../modals/DailyCheckin'
import StartOrScheduleConnection from '../../modals/StartOrScheduleConnection'
import ListExercisesItem from '../ListExercisesItem'
import './index.css'
import { Action as ActionType, Exercise } from '../../types'
import { currentDateTime, parseDateTime } from '../../shared/dates'
import { Entity } from '../../shared/enums'

interface ActionProps {
  action: ActionType
  organizationRoleId?: number
  viewOnly?: boolean
  scheduleType?: string
  actionExercises?: Exercise[]
}

function Action ({ action, organizationRoleId, viewOnly = false, scheduleType, actionExercises }: ActionProps) {
  const completeAction = useUpdateActionMutation()
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const navigate = useNavigate()

  const isSpaceLeaderOrAbove = isAuthorized(getSpaceLeaderPolicy(action?.connection?.spaceId, currentUser?.currentOrganizationId), currentUser)
  const exercise = actionExercises?.find(exercise => exercise?.id === parseInt(action?.description))
  const nextStatus = action?.actionType !== 'EXERCISE' ? action.status === 'COMPLETED' ? 'UNCOMPLETED' : 'COMPLETED' : 'REVIEW'
  const isCompletedOrReview = action?.status === 'COMPLETED' || action?.status === 'REVIEW'
  const [isMinimumView, setIsMinimumView] = useState(window.innerWidth < 600)

  useEffect(() => {
    const handleResize = () => {
      const resizeTimer = setTimeout(() => {
        setIsMinimumView(false)
      }, 50)

      if (window.innerWidth < 600) {
        setIsMinimumView(true)
        clearTimeout(resizeTimer)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function getDailyCheckin ({ description, actionId }) {
    makeModal({
      modal: <DailyCheckin actionId={actionId}/>,
      title: ` ${description}`
    })
  }

  function rescheduleConnection (connection) {
    navigate(`/spaces/${action?.connection?.spaceId}`)
    makeModal({
      modal: <StartOrScheduleConnection space={action?.connection?.space} connection={connection}/>,
      title: `Reschedule ${Entity.Event} for ${action?.connection?.space?.name}`
    })
  }

  const futureConnectDisplay = (
    <div className={`connection-item ${!isMinimumView && 'action-connection-item'}`} key={action?.connection?.uid}>
      <div className="connection-in-progress-settings-container">
        { !isMinimumView
          ? <>
              {isSpaceLeaderOrAbove && <div className="button secondary reschedule" onClick={() => rescheduleConnection(action?.connection)}>
               Reschedule {Entity.Event}
              </div>}
              <div className="button secondary" onClick={() => navigate(`/connections/${action?.connection?.uid}`)}>
                Prepare {Entity.Event}
             </div>
           </>
          : <>
              {isSpaceLeaderOrAbove && <ActionSettingsMenu actionId={action?.id} existingAction={action} currentUser={currentUser} rescheduleConnection={rescheduleConnection} />}
            </>
        }
      </div>
    </div>
  )

  const liveConnectDisplay = (
    <div className="connection-item action-connection-item" key={action?.connection?.uid}>
      <div style={{ display: 'flex' }}>
        {isAuthorized(getSpacePolicy(action?.connection?.spaceId, currentUser?.currentOrganizationId), currentUser) && !action?.connection?.endTime &&
          <div className="button" onClick={() => navigate(`/connections/${action?.connection?.uid}`)} style={{ marginLeft: '0.5rem' }}>
            Join
          </div>
        }
      </div>
    </div>
  )

  const pastConnectDisplay = (
    <div className={`connection-item ${!isMinimumView && 'action-connection-item'}`} key={action?.connection?.uid}>
      <div className="connection-in-progress-settings-container">
        <div className="button secondary" onClick={() => navigate(`/connections/${action?.connection?.uid}`)}>
          Review {Entity.Event}
        </div>
      </div>
    </div>
  )

  return (
    <div className="video-and-action-wrapper">
      <div style={{ padding: '0 0.5rem 0 0.5rem' }}>
       {exercise?.url && isYouTubeUrl(exercise.url) &&
        <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${getYouTubeVideoId(exercise?.url)}?autoplay=1`}
          allow="encrypted-media"
          allowFullScreen
        ></iframe>}
        {exercise?.url && isVimeoUrl(exercise.url) &&
          <iframe
            src={`https://player.vimeo.com/video/${getVimeoVideoId(exercise?.url)}?autoplay=1`}
            width="100%"
            height="315"
            allow="fullscreen"
            allowFullScreen
          ></iframe>}
      </div>
      <div className="uncompleted-action-container" key={action?.id}>
        <div style={{ display: 'flex', alignItems: 'center', width: action?.actionType === 'EXERCISE' ? '100%' : 'auto', paddingRight: action?.actionType === 'EXERCISE' ? '1.5rem' : 'auto' }}>
          {((action?.actionType !== 'CONNECT' && action?.actionType !== 'DAILY_CHECKIN') || isCompletedOrReview) && <Checkbox disabled={isCompletedOrReview} defaultChecked={isCompletedOrReview} onChange={(e) => completeAction.mutate({ id: parseInt(e.target.value), status: nextStatus, organizationRoleId, description: action?.description, spaceId: undefined, dueBy: undefined })} value={action?.id} />}
          {action?.actionType !== 'EXERCISE' && <div className={action?.actionType === 'DAILY_CHECKIN' ? 'action-checkin-description' : action?.actionType === 'CONNECT' ? 'action-connect-description' : 'action-description' }>
            {action?.actionType === 'CONNECT' ? formatConnectionDate(action?.dueBy) : action?.description}
            <div style={{ opacity: 0.5, fontSize: '0.85rem', marginTop: '0.15rem' }}>{action?.space?.name}</div>
          </div> }
          {action?.actionType === 'EXERCISE' &&
            <ListExercisesItem
              currentUser={currentUser}
              key={exercise?.id}
              organizationRoleId={organizationRoleId}
              exercise={exercise}
              readOnly={true}
              isAction={true}
            />
          }
        </div>
        {scheduleType === 'NOW' && action?.actionType === 'CONNECT' && !isCompletedOrReview && ((parseDateTime(action?.dueBy) < currentDateTime()) ? liveConnectDisplay : futureConnectDisplay)}
        {scheduleType !== 'NOW' && action?.actionType === 'CONNECT' && !isCompletedOrReview && futureConnectDisplay}
        {scheduleType !== 'NOW' && action?.actionType === 'CONNECT' && isCompletedOrReview && pastConnectDisplay }
        {(action?.actionType === 'DAILY_CHECKIN' && !viewOnly) && <div className="checkin-action-settings" onClick={() => getDailyCheckin({ description: action?.description, actionId: action?.id })}>Daily Checkin</div>}
        {(action?.actionType === 'TASK' && !isCompletedOrReview) && <ActionSettingsMenu actionId={action?.id} existingAction={action} currentUser={currentUser} />}
      </div>
    </div>
  )
}

export default Action
