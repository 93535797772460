import { useState } from 'react'
import { useUserMutation } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import Button from '../Button'

export const ProfileOnboarding = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { currentUser, refreshUser } = useAuthContext()
  const { mutateAsync: updateUser } = useUserMutation()

  const validateName = (name: string) => {
    return name.length > 0
  }

  const handleSubmit = async () => {
    if (validateName(firstName) && validateName(lastName)) {
      try {
        if (!currentUser) return
        await updateUser({
          ...currentUser,
          firstName,
          lastName
        })
        await refreshUser()
      } catch (error) {
        console.error('Error updating user:', error)
      }
    }
  }

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <h1 className="title">Welcome to ConnectBetter!</h1>
        <p className="subtitle">
          Before we get started, we need a few details to personalize your experience.
        </p>
        <input
          autoFocus
          type="text"
          placeholder="First name"
          onChange={(event) => setFirstName(event.target.value)}
          value={firstName}
        />
        <input
          type="text"
          placeholder="Last name"
          onChange={(event) => setLastName(event.target.value)}
          value={lastName}
        />
        <Button onClick={handleSubmit}>
          Continue
        </Button>
      </div>
    </div>
  )
}
