import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { PuffLoader } from 'react-spinners'

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
  className?: string;
  secondary?: boolean;
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
  testId?: string;
  variant?: 'primary' | 'secondary' | 'ghost';
}

function Button ({
  children,
  to,
  href,
  onClick,
  className,
  secondary,
  size,
  isLoading,
  testId,
  variant,
  ...props
}: ButtonProps) {
  const classNames = [className, 'button']
  if (secondary) classNames.push('secondary')
  if (size) classNames.push(size)
  if (variant !== 'secondary') classNames.push(variant)

  const nextClassName = clsx(classNames)

  const content = isLoading ? <PuffLoader color="#fff" size={18} /> : children

  if (to) {
    return (
      <Link to={to} className={nextClassName} data-testid={testId} {...props}>
        {content}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} className={nextClassName} data-testid={testId} {...props}>
        {content}
      </a>
    )
  }

  return (
    <button
      className={nextClassName}
      onClick={onClick}
      data-testid={testId}
      {...props}
    >
      {content}
    </button>
  )
}

export default Button
