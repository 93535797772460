import { getSpaceLeaderPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { useSpaceQuery } from '../../shared/queryHooks'
import { isAuthorized } from '../../shared/permissions'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import ConnectionHistoryList from '../../components/ConnectionHistoryList'
import ConnectionsInProgress from '../../components/ConnectionsInProgress'
import LoadingScreen from '../../components/LoadingScreen'
import SecondaryNavSpaceAvatars from '../../components/SecondaryNavSpaceAvatars'
import AddOrEditSpaceroles from '../../modals/AddOrEditSpaceroles'
import StartOrScheduleConnection from '../../modals/StartOrScheduleConnection'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import './index.css'
import Button from '../../components/Button'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import ConnectionsUpcoming from '../../components/ConnectionsUpcoming'
import { getSpace } from '../../shared/api'
import { NavigationAction } from '../../types'
import { currentDateTime, parseDateTime } from '../../shared/dates'
import ManageSpace from '../../modals/ManageSpace'
import ErrorPage from '../ErrorPage'
import { Entity } from '../../shared/enums'

interface SpaceProps {
  inOrganizationSettings?: boolean;
}

function Space ({ inOrganizationSettings = false }: SpaceProps) {
  const [searchParams] = useSearchParams()
  const paramsCreatedSpace = searchParams.get('created')
  const paramsAction = searchParams.get('action') ?? undefined
  const secondaryNavNavigation = inOrganizationSettings ? '/communities/settings/spaces' : paramsAction ? '/' : '/spaces'
  const secondaryNavTitle = paramsAction ? 'Actions' : 'Spaces'
  const navigate = useNavigate()
  const { makeModal } = useModal()
  const { id } = useParams()
  const { currentUser } = useAuthContext()
  const [time, setTime] = useState(new Date())
  const idParam = parseInt(id ?? '0')

  useEffect(() => {
    async function handleNewSpaceAndTime () {
      if (paramsCreatedSpace) {
        const space = await getSpace(id)
        makeModal({
          modal: (
            <AddOrEditSpaceroles
              existingSpace={space}
              created={!!paramsCreatedSpace}
            />
          ),
          title: `Space Members for ${space?.name}`,
          className: 'manage-space-modal'
        })
        navigate(`/spaces/${id}`)
      }
      const timer = setInterval(() => setTime(new Date()), 1000)
      return () => clearInterval(timer)
    }
    void handleNewSpaceAndTime()
  }, [])

  const {
    isLoading,
    isError,
    data: space,
    error
  } = useSpaceQuery(idParam, { enabled: !!currentUser })

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    const e: any = error
    const message =
      e?.request?.status === 404
        ? '404, this page does not exist'
        : 'Something went wrong'
    return (
      <>
        <SecondaryNavBar backTo="/" title="Space" />
        <ErrorPage
          message={message}
          action={() => navigate('/')}
          actionTitle="Return home?"
        />
      </>
    )
  }

  if (!space) {
    return (
      <ErrorPage
        message="404, this page does not exist"
        action={() => navigate('/')}
        actionTitle="Return home?"
      />
    )
  }

  const isSpaceLeader = isAuthorized(
    getSpaceLeaderPolicy(idParam, currentUser?.currentOrganizationId),
    currentUser
  )

  const completedConnections = space?.connections?.filter(
    (connection) => connection?.endTime
  )
  const inProgressConnections = space?.connections?.filter(
    (connection) =>
      !connection?.endTime &&
      currentDateTime() > parseDateTime(connection?.startTime)
  )
  const futureConnections = space?.connections?.filter(
    (connection) =>
      !connection?.endTime &&
      currentDateTime() < parseDateTime(connection?.startTime)
  )

  const getSubnavAction = () => {
    const actions: NavigationAction[] = []

    actions.push({
      action: () => {
        makeModal({
          modal: <ManageSpace space={space} />,
          title: 'People',
          className: 'manage-space-modal'
        })
      },
      actionTitle: <SecondaryNavSpaceAvatars spaceRoles={space?.spaceRoles} />,
      actionClassName: `secondary icon ${
        isSpaceLeader ? 'avatar-button' : 'avatar-button-member'
      }`
    })

    return actions
  }

  function startConnection () {
    makeModal({
      modal: <StartOrScheduleConnection space={space} />,
      title: `New ${Entity.Event} for ${space?.name}`
    })
  }

  function getGreetingTime () {
    const currentHour = parseFloat(format(new Date(), 'H'))
    if (currentHour < 12) {
      return 'Good morning,'
    } else if (currentHour < 18) {
      return 'Good afternoon,'
    } else {
      return 'Good evening,'
    }
  }

  return (
    <div className="space-container">
      <SecondaryNavBar
        backTo={secondaryNavNavigation}
        title={secondaryNavTitle}
        onClickActions={getSubnavAction()}
      />
      <div className="space-page-container">
        <div className="connection-history-container">
          <h1 className="space-title">{space.name}</h1>
          {!inOrganizationSettings && (
            <>
              <div className="space-current-datetime">
                {format(time, 'E LLL d, h:mm a')}
              </div>
              <h1 className="space-greeting">
                {getGreetingTime()}
                <br />
                {currentUser?.firstName}
              </h1>
              {isSpaceLeader && (
                <Button
                  className="button button-new-connect"
                  onClick={startConnection}
                >
                  <AddRoundedIcon /> New {Entity.Event}
                </Button>
              )}
            </>
          )}
          {inOrganizationSettings && space?.connections.length === 0 && (
            <div className="space-no-connections">
              There are no {Entity.Event}s in this space.
            </div>
          )}
          <ConnectionsInProgress
            spaceId={idParam}
            organizationId={currentUser?.currentOrganizationId ?? 0}
            connections={inProgressConnections}
            currentUser={currentUser}
          />
          <ConnectionsUpcoming connections={futureConnections} space={space} />
          <ConnectionHistoryList connections={completedConnections} />
        </div>
      </div>
    </div>
  )
}

export default Space
